import { Box, Button, Paper, Stepper, Step, StepLabel, Snackbar, Alert, Divider, Grid2 as Grid } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { createRef, useState, useRef, useEffect } from 'react';
import { ImageNotSupported } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';

import { formatPhoneNumber, getDynamicRequired, setResizedBusinessLogo, states } from '../../helpers';
import { steps, stepFields, calculateStepsStatus } from './steps';
import SelectTextField from '../../components/fields/select-text-field';
import UploadFileField from '../../components/fields/upload-file-field';
import TextInputField from '../../components/fields/text-input-field';
import CheckboxField from '../../components/fields/checkbox-field';
import SelectField from '../../components/fields/select-field';
import RadioField from '../../components/fields/radio-field';
import CheckmarkAnimation from '../../components/checkmark';
import PageLoader from '../../components/page-loader';
import useDebounce from '../../hooks/debounce';

import styles from './index.module.css';

const Intake = () => {
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const notificationId = searchParams.get('notificationId');

  const initialFormData = stepFields.flat().reduce((acc, field) => {
    if (field.name) {
      acc[field.name] = '';
    }
    return acc;
  }, {});

  const refs = useRef([]);
  if (Object.keys(refs.current).length === 0) {
    stepFields.flat().forEach((field) => {
      if (field.name && !refs.current[field.name]) {
        refs.current[field.name] = createRef();
      }
    });
  }

  const [dynamicState, setDynamicState] = useState({ states });
  const [formData, setFormData] = useState(initialFormData);
  const [docsRequested, setDocsRequested] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isRequesting, setIsRequesting] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [patientName, setPatientName] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState({});
  const [errors, setErrors] = useState({});

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState({});

  const getFileFields = () => {
    return stepFields
      .flatMap((step) => step)
      .filter((item) => item.type === 'file')
      .map((file) => file.name);
  };

  const validateCurrentStep = () => {
    const newErrors = {};

    for (const field of stepFields[activeStep]) {
      const { name, required, pattern } = field;
      const value = formData[name];

      const isFieldRequired = getDynamicRequired(formData, required);
      if (isFieldRequired) {
        if (Array.isArray(value) && value.length === 0) {
          newErrors[name] = true;
        } else if (!value || (value && value.trim && value.trim() === '') || value.length < 1) {
          newErrors[name] = true;
        }
      }
      if (isFieldRequired && pattern && value && !new RegExp(pattern).test(value)) {
        newErrors[name] = true;
      }
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleStepClick = (index) => {
    if (formComplete) {
      return;
    }

    const lastStepCompleted = completedSteps[completedSteps.length - 1];
    if (completedSteps.includes(index) || index === lastStepCompleted + 1) {
      setActiveStep(index);
    }
  };

  const handleNext = () => {
    const fieldErrors = validateCurrentStep();
    if (Object.keys(fieldErrors).length > 0) {
      focusOnFirstError(fieldErrors);
      return;
    }

    if (!completedSteps.includes(activeStep)) {
      setCompletedSteps((prevState) => [...prevState, activeStep]);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setTimeout(() => document.getElementById('header')?.scrollIntoView({ behavior: 'instant' }));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setTimeout(() => document.getElementById('header')?.scrollIntoView({ behavior: 'instant' }));
  };

  const focusOnFirstError = (errors) => {
    for (const field in errors) {
      if (errors[field] && refs.current[field]?.current) {
        refs.current[field].current.focus();
        break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (activeStep === steps.length - 2) {
      const fieldErrors = validateCurrentStep();
      if (Object.keys(fieldErrors).length === 0) {
        setIsRequesting(true);
        setTimeout(() => document.getElementById('header')?.scrollIntoView({ behavior: 'instant' }));

        const filesForComments = [];
        const uploadData = new FormData();
        const fileFields = getFileFields();
        fileFields.forEach((fileField) => {
          if (formData[fileField]) {
            filesForComments.push(formData[fileField].name);
            uploadData.append('files', formData[fileField]);
          }
        });

        let updatedFormData = { ...formData };
        if (formData['patientIsResponsibleParty'] === 'Yes') {
          updatedFormData = {
            ...formData,
            responsibleSalutation: formData.patientSalutation,
            responsibleFirstName: formData.patientFirstName,
            responsibleLastName: formData.patientLastName,
            responsibleDateOfBirth: formData.patientDateOfBirth,
            responsibleStreetAddress: formData.patientStreetAddress,
            responsibleAddressLine2: formData.patientAddressLine2,
            responsibleCity: formData.patientCity,
            responsibleState: formData.patientState,
            responsibleZip: formData.patientZip,
            responsiblePhone: formData.patientPhone,
            responsibleEmail: formData.patientEmail,
            responsibleRelationshipId: dynamicState['relationships'].filter((r) => r.label === 'Self')[0].value,
          };
        }

        const res = await fetch('/api/patients', {
          method: 'POST',
          headers: {
            'Client-ID': clientId,
            'Notification-ID': notificationId,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...updatedFormData, filesForComments }),
        });
        const resData = await res.json();

        if (res.ok) {
          if (filesForComments.length) {
            await fetch(`/api/patients/docs/${resData.patientId}`, {
              method: 'POST',
              body: uploadData,
            });
          }

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setFormComplete(true);

          setCompletedSteps(Array.from({ length: steps.length }, (_, i) => i));
        } else {
          setToastOpen(true);
          setToastMessage({ label: `Failed to create or update patient!\n${resData.message}`, severity: 'error' });
        }

        setIsRequesting(false);
      } else {
        focusOnFirstError(fieldErrors);
      }
    } else {
      handleNext();
    }
  };

  const getDynamicClasses = (field) => {
    if (typeof field.hidden === 'object') {
      if (typeof field.hidden.value === 'boolean') {
        return field.hidden.value && !formData[field.hidden.name] && styles.hideField;
      }

      return (
        (field.hidden.value.includes(formData[field.hidden.name]) ||
          !formData[field.hidden.name] ||
          formData[field.hidden.name].trim() === '') &&
        styles.hideField
      );
    } else if (field.hidden) {
      return styles.hideField;
    }
  };

  const renderStepContent = (step) => {
    return (
      <Grid container spacing={2} marginBottom={4}>
        {stepFields[step].map((field, index) => {
          return (
            <Grid size={12} key={index} className={getDynamicClasses(field)}>
              {field.type === 'divider' ? (
                <Divider sx={{ borderStyle: 'dotted', margin: '10px 0 15px 0' }} />
              ) : field.type === 'file' ? (
                <UploadFileField
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  setErrors={setErrors}
                  refs={refs}
                  errors={errors}
                />
              ) : field.type === 'select' ? (
                <SelectField
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  setErrors={setErrors}
                  refs={refs}
                  errors={errors}
                  dynamicState={dynamicState}
                />
              ) : field.type === 'radio' ? (
                <RadioField
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  setErrors={setErrors}
                  refs={refs}
                  errors={errors}
                />
              ) : field.type === 'checkbox' ? (
                <CheckboxField
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  setErrors={setErrors}
                  refs={refs}
                  errors={errors}
                />
              ) : field.type === 'select-text' ? (
                <SelectTextField
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  setErrors={setErrors}
                  refs={refs}
                  errors={errors}
                  dynamicState={dynamicState}
                />
              ) : (
                <TextInputField
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  setErrors={setErrors}
                  refs={refs}
                  errors={errors}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  useEffect(() => {
    const getClient = async () => {
      const res = await fetch(`/api/clients/${clientId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (data.client) {
          setClient(data.client);
          if (data.client.business_logo) {
            setResizedBusinessLogo(data.client.business_logo, 200, setClient);
          }
        } else {
          setNotFound(true);
        }
      } else {
        setNotFound(true);
      }
    };

    const getNotificationById = async () => {
      const res = await fetch(`/api/notifications/${notificationId}`, {
        method: 'GET',
        headers: {
          'Client-ID': clientId,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (!data.notification_id) {
          setNotFound(true);
        } else {
          setFormData((prevState) => ({
            ...prevState,
            patientId: data.patient_id,
          }));
          setPatientName(`${data.first_name}`);
        }
      } else {
        setNotFound(true);
      }
    };

    const getIntakeData = async () => {
      const res = await fetch('/api/intake-data', {
        method: 'GET',
        headers: {
          'Client-ID': clientId,
          'Notification-ID': notificationId,
          'Content-Type': 'application/json',
        },
      });

      if (res.ok) {
        const data = await res.json();
        if (data) {
          if (Object.keys(data.intake).length) {
            setFormData((prevState) => ({
              ...prevState,
              ...data.intake,
            }));
          }
          setDynamicState((prevState) => ({
            ...prevState,
            schools: data.schools.map((s) => ({
              label: s.Name,
              value: s.Id,
            })),
            salutations: data.salutations.map((s) => ({
              label: s,
              value: s,
            })),
            relationships: data.relationships.map((r) => ({
              label: r.Description,
              value: r.Id,
            })),
            insurances: data.insurances
              .map((r) => ({
                label: r.Name === 'Default Insurance Carrier' ? 'Other' : r.Name,
                value: r.Id,
              }))
              .sort((a, b) => {
                if (a.label === 'Other') return -1;
                if (b.label === 'Other') return 1;
                return a.label.localeCompare(b.label);
              }),
            employers: data.employers
              .map((r) => ({
                label: r.Name === 'Default Employer' ? 'Other' : r.Name,
                value: r.Id,
              }))
              .sort((a, b) => {
                if (a.label === 'Other') return -1;
                if (b.label === 'Other') return 1;
                return a.label.localeCompare(b.label);
              }),
          }));
        }
      }
    };

    const init = async () => {
      await Promise.all([getClient(), getNotificationById()]);
      await getIntakeData();
      setLoading(false);
    };

    init();
  }, [clientId, notificationId]);

  useEffect(() => {
    if (loading || docsRequested || !formData['patientId']) return;

    async function getPatientDocs() {
      const fileFields = getFileFields();
      const documents = fileFields
        .filter((fileField) => formData[fileField])
        .map((fileField) => ({
          field: fileField,
          value: formData[fileField],
        }));

      if (documents.length) {
        try {
          const fetchPromises = documents.map(async (document) => {
            const res = await fetch(`/api/patients/docs/${formData['patientId']}/fetch`, {
              method: 'POST',
              headers: {
                'Client-ID': clientId,
                'Notification-ID': notificationId,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ documentFileName: document.value }),
            });

            if (res.ok) {
              const data = await res.blob();
              return { field: document.field, file: new File([data], document.value, { type: data.type }) };
            }
          });

          const results = await Promise.all(fetchPromises);
          results.forEach((result) => {
            if (result) {
              setFormData((prevState) => ({
                ...prevState,
                [result.field]: result.file,
              }));
            }
          });
        } catch (error) {
          console.error('Error fetching patient documents:', error);
        }
      }
    }

    setDocsRequested(true);
    getPatientDocs();
  }, [loading, docsRequested, formData['patientId'], clientId, notificationId]);

  useEffect(() => {
    if (clientId && notificationId) {
      const status = calculateStepsStatus(completedSteps, formComplete, activeStep);
      const getAndSetNotification = async () => {
        await fetch(`/api/notifications/${notificationId}`, {
          method: 'PATCH',
          headers: {
            'Client-ID': clientId,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status }),
        });
      };
      getAndSetNotification();
    }
  }, [clientId, notificationId, completedSteps, formComplete, activeStep]);

  const patchIntakeData = async () => {
    if (!loading && !isRequesting && !formComplete && Object.keys(formData).length) {
      const cleanFormData = { ...formData };
      const fileFields = getFileFields();
      fileFields.forEach((fileField) => {
        delete cleanFormData[fileField];
      });

      await fetch(`/api/intake-data`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: cleanFormData,
          notificationId,
          clientId,
        }),
      });
    }
  };
  useDebounce(patchIntakeData, 500, [loading, isRequesting, formComplete, formData]);

  if (loading) {
    return <PageLoader isRequesting={true} />;
  }

  return (
    <main className={styles.main}>
      <header className={styles.header} id="header">
        <Grid container spacing={0} alignItems="center" justifyContent="space-between">
          <Grid size={6}>
            {client.businessLogo ? (
              <img
                alt={client.businessName}
                src={client.businessLogo}
                title={client.businessName}
                className={styles.businessLogo}
                style={{ maxWidth: client.businessLogoWidth }}
              />
            ) : (
              <ImageNotSupported fontSize="large" color="disabled" />
            )}
          </Grid>
          <Grid size={6}>
            {client.phone && (
              <h2>
                <a href={`tel:${client.phone}`}>
                  <PhoneIcon /> {formatPhoneNumber(client.phone)}
                </a>
              </h2>
            )}
          </Grid>
        </Grid>
      </header>

      {notFound ? (
        <Paper elevation={2} className={styles.paperNotFound}>
          <h1>
            <span>404</span>
            <br />
            Page Not Found
          </h1>
          <p>The page you are looking for does not exist.</p>
        </Paper>
      ) : (
        <Paper elevation={2} className={styles.paper}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel className={styles.stepper}>
              {steps.map((label, index) => (
                <Step key={label} completed={completedSteps.includes(index)} sx={{ cursor: 'pointer' }}>
                  <StepLabel onClick={() => handleStepClick(index)}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={styles.formContainer}>
              {activeStep === 0 ? (
                <>
                  <h1 className={styles.patientName}>Hi {patientName},</h1>
                  <h3 className={styles.mainTitle}>Please enter your information below</h3>
                  <p className={styles.mainDescription}>
                    We value your privacy and security. All information you provide will be kept confidential and securely stored.
                    Your personal details, including sensitive data, are protected using the latest encryption technology to
                    ensure your privacy. Please take a moment to complete the form carefully. If you have any questions, feel free
                    to reach out!
                  </p>
                </>
              ) : formComplete ? (
                <>
                  <CheckmarkAnimation message="Registration Complete!" />
                  <p className={styles.mainDescription}>
                    We value your privacy and security. All information you provided will be kept confidential and securely
                    stored. Your personal details, including sensitive data, are protected using the latest encryption technology
                    to ensure your privacy.
                    <br />
                    <br />
                    <strong>Please see the front desk.</strong>
                  </p>
                </>
              ) : null}
              {!formComplete ? (
                <form onSubmit={handleSubmit} noValidate>
                  {renderStepContent(activeStep)}
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    <Button variant="contained" type="submit">
                      {activeStep === steps.length - 2 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </form>
              ) : null}
            </div>
          </Box>
        </Paper>
      )}
      <footer>
        <p>Copyright &copy; 2024 - Health of Your Space, LLC.</p>
      </footer>
      <PageLoader isRequesting={isRequesting} />
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={() => setToastOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setToastOpen(false)} severity={toastMessage.severity} sx={{ width: '100%' }}>
          {toastMessage.label}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Intake;
