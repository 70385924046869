import { Box, TextField } from '@mui/material';

import { autoFormatters, getDynamicRequired } from '../../helpers';

const TextInputField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: field.autoFormat ? autoFormatters[field.autoFormat](value) : value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom={field.marginBottom}>
      <TextField
        fullWidth
        slotProps={{
          htmlInput: {
            maxLength: field.maxLength,
          },
          inputLabel: {
            shrink: true,
            style: {
              width: '120%',
              overflow: 'visible',
              whiteSpace: 'normal',
              textOverflow: 'unset',
            },
          },
        }}
        sx={field.sx}
        id={field.name}
        key={field.name}
        name={field.name}
        type={field.type}
        label={field.label}
        value={formData[field.name]}
        onChange={handleInputChange}
        error={!!errors[field.name]}
        inputRef={refs.current[field.name]}
        helperText={!!errors[field.name] && field.helperText}
        required={getDynamicRequired(formData, field.required)}
      />
    </Box>
  );
};

export default TextInputField;
