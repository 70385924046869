export const steps = ['Patient', 'Dental', 'Medical', 'Insurance', 'Finish'];

export const stepFields = [
  [
    {
      name: 'patientSalutation',
      label: 'Title',
      type: 'select',
      required: false,
      optionsByDynamicStateKey: 'salutations',
    },
    {
      name: 'patientFirstName',
      label: 'Legal First Name',
      type: 'text',
      required: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientLastName',
      label: 'Legal Last Name',
      type: 'text',
      required: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientPreferredName',
      label: 'Preferred Name',
      type: 'text',
      required: false,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientDateOfBirth',
      label: 'Date of Birth',
      type: 'date',
      required: true,
    },
    {
      name: 'patientStreetAddress',
      label: 'Street Address',
      type: 'text',
      required: true,
    },
    {
      name: 'patientAddressLine2',
      label: 'Address Line 2',
      type: 'text',
      required: false,
    },
    {
      name: 'patientCity',
      label: 'City',
      type: 'text',
      required: true,
    },
    {
      name: 'patientState',
      label: 'State',
      type: 'select',
      required: true,
      optionsByDynamicStateKey: 'states',
    },
    {
      name: 'patientZip',
      label: 'Zip',
      type: 'number',
      required: true,
      pattern: '^\\d{5}$',
      autoFormat: 'autoMaxZipLength',
    },
    {
      name: 'patientPhone',
      label: 'Phone',
      type: 'tel',
      required: true,
      pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      helperText: 'Phone format: XXX-XXX-XXXX',
      autoFormat: 'autoFormatPhoneNumber',
      maxLength: 12,
    },
    {
      name: 'patientEmail',
      label: 'Email Address',
      type: 'email',
      required: true,
      pattern: '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$',
    },
    {
      type: 'divider',
    },
    {
      name: 'patientSchoolName',
      label: 'School Name (if applicable)',
      type: 'select',
      required: false,
      optionsByDynamicStateKey: 'schools',
    },
    {
      name: 'patientGrade',
      label: 'Grade (if applicable)',
      type: 'text',
      required: false,
      maxLength: 2,
    },
    {
      name: 'patientExtracurricularActivities',
      label: 'List any sports, hobbies, or musical instruments played',
      type: 'textarea',
      required: false,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
    {
      name: 'patientFriendsFamilyAtOffice',
      label: 'Do you have any friends or family currently at Imagine?',
      type: 'text',
      required: false,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
    {
      name: 'patientHearAboutUs',
      label: 'How did you hear about us?',
      type: 'text',
      required: false,
    },
    {
      name: 'patientPreviousOrthodonticConsultation',
      label: 'Have you had a previous orthodontic consultation?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientPreviousOrthodonticConsultationWhenWhere',
      label: 'If yes, when/where?',
      type: 'text',
      required: {
        name: 'patientPreviousOrthodonticConsultation',
        value: ['Yes'],
      },
      hidden: {
        name: 'patientPreviousOrthodonticConsultation',
        value: ['No'],
      },
    },
    {
      name: 'patientOrthodonticIssuesOrConcerns',
      label: 'What are your orthodontic issues or concerns?',
      type: 'textarea',
      required: true,
    },
    {
      type: 'divider',
    },
    {
      name: 'patientIsResponsibleParty',
      label: 'Is the patient the same as the responsible party?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
      marginBottom: 2,
    },
    {
      name: 'responsibleRelationshipId',
      label: 'Relationship to Patient',
      type: 'select',
      optionsByDynamicStateKey: 'relationships',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleSalutation',
      label: 'Title',
      type: 'select',
      required: false,
      optionsByDynamicStateKey: 'salutations',
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleFirstName',
      label: 'Legal First Name',
      type: 'text',
      autoFormat: 'autoFormatCapitalize',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleLastName',
      label: 'Legal Last Name',
      type: 'text',
      autoFormat: 'autoFormatCapitalize',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleDateOfBirth',
      label: 'Date of Birth',
      type: 'date',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleStreetAddress',
      label: 'Street Address',
      type: 'text',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleAddressLine2',
      label: 'Address Line 2',
      type: 'text',
      required: false,
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleCity',
      label: 'City',
      type: 'text',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleState',
      label: 'State',
      type: 'select',
      optionsByDynamicStateKey: 'states',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleZip',
      label: 'Zip',
      type: 'number',
      pattern: '^\\d{5}$',
      autoFormat: 'autoMaxZipLength',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsiblePhone',
      label: 'Phone',
      type: 'tel',
      pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
      helperText: 'Phone format: XXX-XXX-XXXX',
      autoFormat: 'autoFormatPhoneNumber',
      maxLength: 12,
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      name: 'responsibleEmail',
      label: 'Email Address',
      type: 'email',
      pattern: '^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$',
      required: {
        name: 'patientIsResponsibleParty',
        value: ['No'],
      },
      hidden: {
        name: 'patientIsResponsibleParty',
        value: ['Yes'],
      },
    },
    {
      type: 'divider',
    },
    {
      name: 'photoIDFront',
      label: 'Upload a Valid Photo ID (Front)',
      type: 'file',
      required: true,
      helperText: 'Valid Photo Required',
    },
    {
      name: 'photoIDBack',
      label: 'Upload a Valid Photo ID (Back)',
      type: 'file',
      required: true,
      helperText: 'Valid Photo Required',
    },
    {
      name: 'responsibleEmployerId',
      label: 'Employer',
      type: 'select',
      required: true,
      optionsByDynamicStateKey: 'employers',
    },
    {
      name: 'responsibleOccupation',
      label: 'Occupation',
      type: 'text',
      required: true,
    },
    {
      name: 'responsibleIfDivorceIWhoIsCustodialParent',
      label: 'If divorce is involved, who is the Custodial Parent?',
      type: 'text',
      required: false,
      marginBottom: 2,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
    {
      name: 'responsibleMayPatientInformationBeReleasedToNonCustodialParent',
      label: 'May patient information be released to the non-custodial parent?',
      type: 'radio',
      required: {
        name: 'responsibleIfDivorceIWhoIsCustodialParent',
        value: true,
      },
      hidden: {
        name: 'responsibleIfDivorceIWhoIsCustodialParent',
        value: true,
      },
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
  ],
  [
    {
      name: 'primaryDentistName',
      label: 'Primary Dentist Name',
      type: 'text',
      required: false,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'otherDentalSpecialists',
      label: 'Other Dental Specialists',
      type: 'text',
      required: false,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      type: 'divider',
    },
    {
      name: 'bracesOrInvisalign',
      label: 'Does the patient prefer Braces or Invisalign?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Braces', value: 'Braces' },
        { label: 'Invisalign', value: 'Invisalign' },
      ],
    },
    {
      name: 'anyoneConsideringOrthodontics',
      label: 'Is anyone else in the family considering orthodontics?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'whoConsideringOrthodontics',
      label: 'If yes, who?',
      type: 'text',
      required: {
        name: 'anyoneConsideringOrthodontics',
        value: ['Yes'],
      },
      hidden: {
        name: 'anyoneConsideringOrthodontics',
        value: ['No'],
      },
    },
    {
      name: 'hasPatientRegularCheckups',
      label: 'Has the patient had regular checkups?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'lastDentalExamDate',
      label: 'Last Dental Exam Date',
      type: 'date',
      required: {
        name: 'hasPatientRegularCheckups',
        value: ['Yes'],
      },
      hidden: {
        name: 'hasPatientRegularCheckups',
        value: ['No'],
      },
      sx: { minHeight: '24px' },
    },
    {
      name: 'lastDentalXRaysDate',
      label: 'Last Dental X-Rays Date',
      type: 'date',
      required: {
        name: 'hasPatientRegularCheckups',
        value: ['Yes'],
      },
      hidden: {
        name: 'hasPatientRegularCheckups',
        value: ['No'],
      },
      sx: { minHeight: '24px' },
    },
    {
      name: 'satisfiedWithPastDentistry',
      label: 'Have you been satisfied with past dentistry?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'ifNotsatisfiedWithPastDentistryExplain',
      label: 'If no, please explain',
      type: 'textarea',
      required: {
        name: 'satisfiedWithPastDentistry',
        value: ['No'],
      },
      hidden: {
        name: 'satisfiedWithPastDentistry',
        value: ['Yes'],
      },
      marginBottom: 2,
    },
    {
      type: 'divider',
    },
    {
      name: 'patientAnyOfTheFollowingPain',
      label: 'Does the patient currently have, or have had any of the following?',
      type: 'checkbox',
      required: true,
      options: [
        { label: 'Thumb Habit', value: 'ThumbHabit' },
        { label: 'Finger Habit', value: 'FingerHabit' },
        { label: 'Head Injury', value: 'HeadInjury' },
        { label: 'Neck Injury', value: 'NeckInjury' },
        { label: 'Nail Biting', value: 'NailBiting' },
        { label: 'Jaw Pain', value: 'JawPain' },
        { label: 'Joint Pain', value: 'JointPain' },
        { label: 'Head Pain', value: 'HeadPain' },
        { label: 'Neck Pain', value: 'NeckPain' },
        { label: 'Periodontal Disease', value: 'PeriodontalDisease' },
        { label: 'Cold Sores', value: 'ColdSores' },
        { label: 'Clenching', value: 'Clenching' },
        { label: 'Grinding', value: 'Grinding' },
        { label: 'Gum Surgery', value: 'GumSurgery' },
        { label: 'Food Traps', value: 'FoodTraps' },
        { label: 'None', value: 'None' },
      ],
      marginBottom: 2,
    },
    {
      name: 'patientOtherDentalInformation',
      label: 'Is there any other dental information we should know about?',
      type: 'text',
      required: false,
      sx: {
        '@media (max-width: 480px)': {
          paddingTop: '16px',
        },
      },
    },
  ],
  [
    {
      name: 'patienPrimaryPhysiciansName',
      label: "Primary Physician's Name",
      type: 'text',
      required: true,
      autoFormat: 'autoFormatCapitalize',
    },
    {
      name: 'patientOverallHealth',
      label: "Patient's Overall Health",
      type: 'select',
      required: true,
      options: [
        { label: 'Excellent', value: 'Excellent' },
        { label: 'Good', value: 'Good' },
        { label: 'Poor', value: 'Poor' },
      ],
    },
    {
      type: 'divider',
    },
    {
      name: 'patientHaveAllergies',
      label: 'Does the patient have any allergies (drugs / food / pollen)?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientListOfAllergies',
      label: 'If yes, please list',
      type: 'text',
      required: {
        name: 'patientHaveAllergies',
        value: ['Yes'],
      },
      hidden: {
        name: 'patientHaveAllergies',
        value: ['No'],
      },
    },
    {
      name: 'patientCurrentMedicalCare',
      label: 'Is the patient currently under medical care?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientCurrentMedicalCareWhereWhen',
      label: 'If yes, where/when?',
      type: 'text',
      required: {
        name: 'patientCurrentMedicalCare',
        value: ['Yes'],
      },
      hidden: {
        name: 'patientCurrentMedicalCare',
        value: ['No'],
      },
    },
    {
      name: 'patientCurrentMedications',
      label: 'Is the patient currently taking any medications?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientListOfMedications',
      label: 'If yes, please list',
      type: 'text',
      required: {
        name: 'patientCurrentMedications',
        value: ['Yes'],
      },
      hidden: {
        name: 'patientCurrentMedications',
        value: ['No'],
      },
    },
    {
      name: 'patientBeenHospitalized',
      label: 'Has the patient ever been hospitalized?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'patientBeenHospitalizedWhereWhen',
      label: 'If yes, where/when?',
      type: 'text',
      required: {
        name: 'patientBeenHospitalized',
        value: ['Yes'],
      },
      hidden: {
        name: 'patientBeenHospitalized',
        value: ['No'],
      },
    },
    {
      type: 'divider',
    },
    {
      name: 'patientConditions',
      label: 'Does the patient currently have, or have had any of the following?',
      type: 'checkbox',
      required: true,
      options: [
        { label: 'Adenoid Removed', value: 'AdenoidRemoved' },
        { label: 'Drug History', value: 'DrugHistory' },
        { label: 'Major Surgery', value: 'MajorSurgery' },
        { label: 'AIDS (HIV)', value: 'AIDS_HIV' },
        { label: 'Epilepsy / Seizures', value: 'EpilepsySeizures' },
        { label: 'Nasal / Airway Problems', value: 'NasalAirwayProblems' },
        { label: 'Arthritis', value: 'Arthritis' },
        { label: 'Heart Problems', value: 'HeartProblems' },
        { label: 'Sinus Problems', value: 'SinusProblems' },
        { label: 'Asthma', value: 'Asthma' },
        { label: 'Hepatitis A, B, or C', value: 'Hepatitis' },
        { label: 'Speech Problems', value: 'SpeechProblems' },
        { label: 'Auto Accident', value: 'AutoAccident' },
        { label: 'High Blood Pressure', value: 'HighBloodPressure' },
        { label: 'Tobacco Usage', value: 'TobaccoUsage' },
        { label: 'Bleeding Disorders', value: 'BleedingDisorders' },
        { label: 'Immune Disorders', value: 'ImmuneDisorders' },
        { label: 'Tonsils Removed', value: 'TonsilsRemoved' },
        { label: 'Cancer', value: 'Cancer' },
        { label: 'Kidney Problems', value: 'KidneyProblems' },
        { label: 'Tuberculosis', value: 'Tuberculosis' },
        { label: 'Cosmetic Surgery', value: 'CosmeticSurgery' },
        { label: 'Liver Problems', value: 'LiverProblems' },
        { label: 'Tubes in Ears', value: 'TubesInEars' },
        { label: 'Diabetes', value: 'Diabetes' },
        { label: 'Lung Problems', value: 'LungProblems' },
        { label: 'Venereal Disease', value: 'VenerealDisease' },
        { label: 'None', value: 'None' },
      ],
    },
  ],
  [
    {
      name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
      label: 'Do you have insurance coverage?',
      type: 'radio',
      required: true,
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ],
    },
    {
      name: 'insuranceCardFront',
      label: 'Upload an Insurance Card (Front)',
      type: 'file',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
      helperText: 'Valid Photo Required',
    },
    {
      name: 'insuranceCardBack',
      label: 'Upload an Insurance Card (Back)',
      type: 'file',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
      helperText: 'Valid Photo Required',
    },
    {
      name: 'insuranceCarrierName',
      label: 'Insurance Company Name',
      type: 'select-text',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      optionsByDynamicStateKey: 'insurances',
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
    },
    // {
    //   name: 'insuranceCarrierAddress',
    //   label: 'Insurance Company Address',
    //   type: 'text',
    //   required: false,
    //   hidden: {
    //     name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
    //     value: ['No'],
    //   },
    // },
    // {
    //   name: 'insuranceCarrierCity',
    //   label: 'City',
    //   type: 'text',
    //   required: false,
    //   hidden: {
    //     name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
    //     value: ['No'],
    //   },
    // },
    // {
    //   name: 'insuranceCarrierState',
    //   label: 'State',
    //   type: 'select',
    //   required: false,
    //   optionsByDynamicStateKey: 'states',
    //   hidden: {
    //     name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
    //     value: ['No'],
    //   },
    // },
    // {
    //   name: 'insuranceCarrierZip',
    //   label: 'Zip',
    //   type: 'number',
    //   required: false,
    //   pattern: '^\\d{5}$',
    //   maxLength: 5,
    //   hidden: {
    //     name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
    //     value: ['No'],
    //   },
    // },
    // {
    //   name: 'insuranceCarrierPhone',
    //   label: 'Insurance Company Phone',
    //   type: 'tel',
    //   required: false,
    //   pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
    //   helperText: 'Phone format: XXX-XXX-XXXX',
    //   autoFormat: 'autoFormatPhoneNumber',
    //   maxLength: 12,
    //   hidden: {
    //     name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
    //     value: ['No'],
    //   },
    // },
    {
      name: 'insuranceCarrierPolicyNumber',
      label: 'Insurance Policy #',
      type: 'text',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      autoFormat: 'autoFormatUppercase',
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
    },
    {
      name: 'insuranceCarrierGroupNumber',
      label: 'Insurance Group / Member #',
      type: 'text',
      required: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['Yes'],
      },
      autoFormat: 'autoFormatUppercase',
      hidden: {
        name: 'responsibleDoYouHaveOrthodonticInsuranceCoverage',
        value: ['No'],
      },
    },
  ],
];

export const calculateStepsStatus = (completedSteps, formComplete, activeStep) => {
  if (completedSteps.length === 0 && activeStep === 0) {
    return 'Link Clicked';
  }

  if (formComplete) {
    return 'Form Completed';
  }

  if (completedSteps.includes(activeStep - 1)) {
    return `Step ${activeStep} Completed`;
  }

  return `In Progress`;
};
