import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

import styles from './fields.module.css';

const RadioField = ({ field, formData, setFormData, setErrors, refs, errors }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginBottom={field.marginBottom}>
      <FormControl fullWidth error={!!errors[field.name]} sx={{ maxWidth: 600, textAlign: 'center' }}>
        <FormLabel id={`radio-label-${field.name}`} sx={{ color: 'black' }}>
          {field.label}
          {field.required && <span className={styles.requiredAsterisk}>&nbsp;*</span>}
        </FormLabel>
        <RadioGroup
          id={field.name}
          key={field.name}
          name={field.name}
          value={formData[field.name]}
          onChange={handleInputChange}
          inputRef={refs.current[field.name]}
          labelId={`radio-label-${field.name}`}
          sx={{ justifyContent: 'center' }}
          row
        >
          {field.options.map((o) => (
            <FormControlLabel key={o.value} value={o.value} control={<Radio />} label={o.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default RadioField;
